.backgroundLogo {
  background: url('./assets/background.jpg') no-repeat top left;
}

.backgroundShell {
  background: url('./assets/backgroundShell.jpg') no-repeat bottom right;
}

#page_content {
  height: 100%;
}
