body,
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: 'Barlow', 'Open Sans', Arial, sans-serif;
  height: 100%;
  min-height: 100%;
  /* word-break: break-word; */
}
.body-container,
#app,
#root {
  height: 100%;
  min-height: 100%;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Barlow', 'Open Sans', Arial, sans-serif !important;
  font-weight: 700;
}

@media (max-width: 776px) {
  .row > div {
    margin-bottom: 2em;
  }
}

.clearfix:after {
  content: ' '; /* Older browser do not support empty content */
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
/* custom */
@media (min-width: 768px) {
  .container {
    max-width: 1025px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1240px;
  }
}
@media (max-width: 992px) {
  body {
    font-size: 0.9rem;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}
